import { useCart } from 'hooks/cart';
import PropTypes from 'prop-types';
import { CartContext } from 'contexts/cart';

export function LocalCartProvider({ shop, children }) {
  const [cart, , , , , refreshCart] = useCart([], shop._id || '-1');
  return (
    <CartContext.Provider
      value={{
        cart,
        refresh: refreshCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

LocalCartProvider.propTypes = {
  shop: PropTypes.object.isRequired,
  children: PropTypes.any,
};

LocalCartProvider.defaultProps = {
  children: null,
};

