import React from 'react';
import PropTypes from 'prop-types';

function JsonSchema({ schema }) {
  return (
    <script
      type={'application/ld+json'}
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(schema)
      }}
    />
  );
}

JsonSchema.propTypes = {
  schema: PropTypes.object.isRequired,
};

export default JsonSchema;
