import { useState, useEffect, useCallback } from 'react';
import {
  addToCart, getCart, deleteCartItem, checkout, updateQuantity, checkoutOrder, applyCode, removeCode, validateCart
} from 'ui/api/cart';
import {
  pollPayOrder, payOrder
} from 'ui/api/orders';
import {
  useIsProfileLoading,
  useIsVisitor,useIsDesktop
} from 'contexts/profile';
import { Loader } from 'ui/services';
import { SnackBar } from 'ui/services';
import { emitEvent, EVENT_TYPES } from 'ui/lib/pixels';
import { useRouter } from 'next/router';
import { useQueryParams } from 'hooks/location';


export default class Cart {
  constructor(id) {
    this.id = id;
  }
  add = async (payload) => {
    try {
      Loader.show();
      const res = await addToCart(this.id, payload);
      emitEvent(EVENT_TYPES.ADD_TO_CART);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  deleteCart = async (item) => {
    try {
      Loader.show();
      const res = await deleteCartItem(this.id, item);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  updateQty = async (item, payload) => {
    try {
      Loader.show();
      const res = await updateQuantity(this.id, item, payload);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  checkoutCart = async payload => {
    try {
      Loader.show();
      const res = await checkout(this.id, payload);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  cartOrder = async (payload) => {
    try {
      Loader.show();
      const res = await checkoutOrder(this.id, payload);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      if (e?.response?.status < 500) {
        return { error: e?.response?.data?.message };
      }
      SnackBar.showError(e);
    }
  };

  poll = async (orderId,pgTid, tid) => {
    try {
      const res = await pollPayOrder(orderId, pgTid, tid);
      return res;
    } catch (e) {
      SnackBar.showError(e);
    }
  };

  apply = async (payload) => {
    try {
      Loader.show();
      const res = await applyCode(this.id, payload);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      if (e?.response?.status < 500) {
        return { error: e?.response?.data?.message };
      }
    }
  };

  remove = async () => {
    try {
      Loader.show();
      const res = await removeCode(this.id);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      if (e?.response?.status < 500) {
        return { error: e?.response?.data?.message };
      }
    }
  };

  get = async (addressId, shippingMode =null) => {
    try {
      Loader.show();
      const data = await getCart(this.id, addressId, shippingMode);
      Loader.hide();
      return data;
    } catch (e) {
      Loader.hide();
      throw e;
    }
  };

  pay = async (orderId,payload) => {
    try {
      Loader.show();
      const res = await payOrder(orderId, payload);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  validate = async () => {
    try {
      Loader.show();
      const res = await validateCart(this.id);
      Loader.hide();
      return res;
    } catch (e) {
      SnackBar.showError(e);
      Loader.hide();
      if (e?.response?.status < 500) {
        if (e?.response?.data?.errors) {
          return  { silent: true, message: e?.response?.data?.errors?.map(x => x.message).join(' '), 
            errors: e?.response?.data?.errors || [] };
        }
        if (e?.response?.data?.message) {
          return { silent: true, message: e?.response?.data?.message };
        }
        return { errors: e?.response?.data?.errors || [] };
      }
    }
  };
}

export function useCartV2(initial = {}, _id) {
  const [cartData, setCartData] = useState(initial);
  const isVisitor = useIsVisitor();
  const service = new Cart(_id);
  const isProfileLoading = useIsProfileLoading();
  const router = useRouter();
  const params = useQueryParams();
  const addressId = params.has('addressId') ? params.get('addressId') : undefined;
  const shippingMode = params.has('shippingMode') ? params.get('shippingMode') : undefined;

  const fetch = useCallback(async ()=>{
    try {
      if (isVisitor) {
        Loader.hide();
        return;
      }
      const data = await service.get(addressId, shippingMode);
      setCartData(data);
    } catch (e) {
      if (e?.response?.status === 403) {
        SnackBar.showError(e?.response?.data?.message);
        router.back();
      }
      SnackBar.showError(e);
    }
  }, [_id, isVisitor, addressId]);

  useEffect(()=>{
    if(!isVisitor) {
      fetch();
    }
  },[_id, isProfileLoading, isVisitor, addressId]);

  return [cartData, service, fetch];
}

export function useShowFullScreen() {
  const params = useQueryParams();
  const isDesktop = useIsDesktop();
  const mode = params.get('paymentMode') || '';
  return !['cod','online'].includes(mode) && params.has('paymentMode') && isDesktop;
}


export function useCart(initial = [], _id = '-1', addressId) {
  const [cartData, setCartData] = useState(initial);
  const isVisitor = useIsVisitor();
  const isProfileLoading = useIsProfileLoading();
  const router = useRouter();

  const get = async () => {
    try {
      Loader.show();
      if (isVisitor) {
        Loader.hide();
        return;
      }
      const data = await getCart(_id, addressId);
      setCartData(data);
      Loader.hide();
    } catch (e) {
      if (e?.response?.status === 403) {
        SnackBar.showError(e?.response?.data?.message);
        router.back();
      }
      SnackBar.showError(e);
      Loader.hide();
    }
  };

  useEffect(() => {
    if (_id !== '-1' && !isVisitor) {
      get(_id);
    }
  }, [isProfileLoading, isVisitor]);

  const add = async (id, payload) => {
    try {
      Loader.show();
      const res = await addToCart(id, payload);
      emitEvent(EVENT_TYPES.ADD_TO_CART);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  const deleteCart = async (id, item) => {
    try {
      Loader.show();
      const res = await deleteCartItem(id, item);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  const updateQty = async (id, item, payload) => {
    try {
      Loader.show();
      const res = await updateQuantity(id, item, payload);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  const checkoutCart = async (id, payload) => {
    try {
      Loader.show();
      const res = await checkout(id, payload);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  const cartOrder = async (id, payload) => {
    try {
      Loader.show();
      const res = await checkoutOrder(id, payload);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      if (e?.response?.status < 500) {
        return { error: e?.response?.data?.message };
      }
      SnackBar.showError(e);
    }
  };

  const refresh = () => {
    if (_id !== '-1') {
      get(_id);
    }
  };

  const poll = async (id, pgTid, tid) => {
    try {
      const res = await pollPayOrder(id, pgTid, tid);
      return res;
    } catch (e) {
      SnackBar.showError(e);
    }
  };

  const apply = async (id, payload) => {
    try {
      Loader.show();
      const res = await applyCode(id, payload);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      if (e?.response?.status < 500) {
        return { error: e?.response?.data?.message };
      }
    }
  };

  const remove = async (id) => {
    try {
      Loader.show();
      const res = await removeCode(id);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      if (e?.response?.status < 500) {
        return { error: e?.response?.data?.message };
      }
    }
  };

  const pay = async (id, payload) => {
    try {
      Loader.show();
      const res = await payOrder(id, payload);
      Loader.hide();
      return res;
    } catch (e) {
      Loader.hide();
      SnackBar.showError(e);
    }
  };

  const validate = async (id) => {
    try {
      Loader.show();
      const res = await validateCart(id);
      Loader.hide();
      return res;
    } catch (e) {
      SnackBar.showError(e);
      Loader.hide();
      if (e?.response?.status < 500) {
        if (e?.response?.data?.message) {
          return { silent: true, message: e?.response?.data?.message };
        }
        return { errors: e?.response?.data?.errors || [] };
      }
    }
  };

  return [cartData, add, deleteCart, updateQty, checkoutCart, refresh, cartOrder, poll, apply, remove, pay, validate];
}
