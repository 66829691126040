import { useEffect } from 'react';
import CONFIG from 'ui/config';

const pollForGa = async () => {
  if (typeof window.gtag === 'function') {
    return;
  }
  let times = 0;
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (times > 10) {
        clearInterval(interval);
        reject();
      }
      if (typeof window.gtag === 'function') {
        clearInterval(interval);
        resolve();
      }
      times += 1;
    }, 500);
  });
};

export function useTrackGa(id, type, pid) {
  useEffect(() => {
    const timeout = setTimeout(async () => {
      try {
        await pollForGa();
        console.log('GA loaded');
        if (window.gaConfigSent) {
          window.gtag('event', 'page_view', {
            'groups': 'analytics',
            'unified_page_path_screen': `${id}:${type}:${pid}`,
            'unified_screen_name': `${id}:${type}:${pid}`,
          });
          return;
        }
        window.gtag('config', CONFIG.env === 'production' ? 'G-FXQ9XDW3M4' : 'G-8XMVB01LDT', {
          'groups': 'analytics',
          'unified_page_path_screen': `${id}:${type}:${pid}`,
          'unified_screen_name': `${id}:${type}:${pid}`,
        });
        window.gaConfigSent = true;
      } catch (e) {
        console.log('GA not loaded');
      }
    }, 50);

    return () => clearTimeout(timeout);
  }, [id, type, pid]);
}
